// export const baseUrl = "http://localhost:5000/api/v1/";
// export const baseUrl  = "http://bi-be.vitessetec.com/api/v1/";
export const baseUrl = "http://bi-back-end.vitessetec.com/api/v1/";

export const loginUrl =  baseUrl+"auth/login";
export const registerUrl = baseUrl + "user/";

export const getExcelFileUrl =
  baseUrl + "media/PL_Reports/PL_Actual_ftvNq3c.xlsx";

export const getPlFileUrl = baseUrl + "media/PL_Reports/PL_Actual_ftvNq3c.xlsx";

export const getRevenueFileUrl = baseUrl + "media/Revenue.xlsx";
export const getReportsFileUrl = baseUrl + "media/Daily_revenue_Report.xlsx";

export const postExcelUrl = baseUrl + "BI/";

// export const baseUrl2 = "http://localhost:5000/api/v1/";
export const baseUrl2 = "https://bi-back-end.vitessetec.com/api/v1/";
export const userUrl = baseUrl2 + "users/";
export const roleUrl = baseUrl2 + "roles/";
